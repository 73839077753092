<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Communal Repair</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="crepair-form">
          <v-autocomplete
            v-if="!fromProperty"
            label="Property*"
            v-model="fields.property_id"
            :items="properties"
            item-value="id"
            item-text="property_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_id')"
            :error-messages="errors['property_id']"
          ></v-autocomplete>

          <v-textarea
            label="Works"
            v-model="fields.short_description"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('short_description')"
            :error-messages="errors['short_description']"
          ></v-textarea>

          <!-- <v-textarea
            label="Description"
            v-model="fields.long_description"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('long_description')"
            :error-messages="errors['long_description']"
          ></v-textarea> -->

          <v-text-field
            label="Contractor"
            v-model="fields.contractor"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('contractor')"
            :error-messages="errors['contractor']"
          ></v-text-field>

          <v-text-field
            label="Quote"
            v-model="fields.quote"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('quote')"
            :error-messages="errors['quote']"
          ></v-text-field>

          <v-text-field
            label="Share"
            v-model="fields.share"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('share')"
            :error-messages="errors['share']"
          ></v-text-field>

          <v-select
            label="Assignee"
            v-model="fields.assignee"
            :items="users"
            item-value="id"
            item-text="full_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('assignee')"
            :error-messages="errors['assignee']"
          ></v-select>

          <v-text-field
            label="Due Date"
            v-model="fields.due_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('due_date')"
            :error-messages="errors['due_date']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="crepair-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
    fromProperty: Boolean,
    property: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      fields: {
        property_id: null,
        short_description: null,
        long_description: null,
        contractor: null,
        quote: null,
        share: null,
        assignee: null,
        due_date: null,
      },

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    users() {
      return this.$store.state.users["users"];
    },

    properties() {
      let properties = this.$store.getters["sbpm/propertiesStore/all"];

      properties = properties.filter((c) => c.status == "Managing");

      return properties;
    },
  },

  methods: {
    open: function (repair = null) {
      if (repair !== null) {
        this.repair = repair;

        this.fields.property_id = repair.property_id
          ? repair.property.id
          : null;

        this.fields.short_description = repair.short_description;
        this.fields.long_description = repair.long_description;
        this.fields.contractor = repair.contractor;
        this.fields.quote = repair.quote;
        this.fields.share = repair.share;

        this.fields.assignee = repair.assignee ? repair.assigne_user.id : null;

        this.fields.due_date = repair.due_date;
        this.isEditing = true;
      }

      if (this.fromProperty) {
        this.fields.property_id = this.property.id;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.fromProperty) {
        payload.propertyId = this.property.id;
        this.dispatch = "sbpm/propertiesStore/saveProperty";
        payload.isEditing = true;
      } else {
        this.dispatch = "sbpm/repairsStore/saveRepair";
      }

      payload.fields.form_title = "repair";

      if (this.isEditing) {
        payload.repairId = this.repair.id;
      }

      this.$store
        .dispatch(this.dispatch, payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields = {
        property_id: null,
        short_description: null,
        long_description: null,
        contractor: null,
        quote: null,
        share: null,
        assignee: null,
        due_date: null,
      };
    },
  },
};
</script>
